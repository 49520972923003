/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useEffect, useRef, useState } from "react"
import { useGLTF, useAnimations, PerspectiveCamera } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"

const color = new THREE.Color()

export default function Model({ scroll, ...props }) {
  const t = useRef(0)
  const group = useRef()
  const { nodes, materials, animations } = useGLTF("model3.glb")
  const { actions, mixer } = useAnimations(animations, group)
  const [hovered, set] = useState()
  const extras = { flatShading: false, receiveShadow: true, castShadow: true, "material-envMapIntensity": 0.2 }
  //console.log(materials)
  useEffect(() => void (actions["CameraAction"].play().paused = true), [])
  useEffect(() => {
    //if (hovered) group.current.getObjectByName(hovered).material.color.set("white")
    document.body.style.cursor = hovered ? "pointer" : "auto"
  }, [hovered])
  useFrame((state) => {
    actions["CameraAction"].time = THREE.MathUtils.lerp(actions["CameraAction"].time, actions["CameraAction"].getClip().duration * scroll.current, 0.005)
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.06, 4.04, 0.35]} scale={0.25}>
        <group position={[-0.23, 49.59, -9.69]} rotation={[2.56, -0.19, -3.08]} scale={10.46}>
          <mesh geometry={nodes.iphone_12022.geometry} material={materials["dark metal.002"]} />
          <mesh geometry={nodes.iphone_12022_1.geometry} material={materials["white line.008"]} />
          <mesh geometry={nodes.iphone_12022_2.geometry} material={materials["red.008"]} />
          <mesh geometry={nodes.iphone_12022_3.geometry} material={materials["blue.013"]} />
          <mesh geometry={nodes.iphone_12022_4.geometry} material={materials["black back.002"]} />
          <mesh geometry={nodes.iphone_12022_5.geometry} material={materials["buttons.008"]} />
          <mesh geometry={nodes.iphone_12022_6.geometry} material={materials["metal.017"]} />
          <mesh geometry={nodes.iphone_12022_7.geometry} material={materials["glass.031"]} />
          <mesh geometry={nodes.iphone_12022_8.geometry} material={materials["screen.031"]} />
          <mesh geometry={nodes.iphone_12022_9.geometry} material={materials["mat.022"]} />
          <mesh geometry={nodes.iphone_12022_10.geometry} material={materials["glass.032"]} />
          <mesh geometry={nodes.iphone_12022_11.geometry} material={materials["screen.032"]} />
          <mesh geometry={nodes.iphone_12022_12.geometry} material={materials["mat.023"]} />
          <mesh geometry={nodes.iphone_12022_13.geometry} material={materials["glass.033"]} />
          <mesh geometry={nodes.iphone_12022_14.geometry} material={materials["screen.033"]} />
          <mesh geometry={nodes.iphone_12022_15.geometry} material={materials["mat.024"]} />
          <mesh geometry={nodes.iphone_12022_16.geometry} material={materials["orange.008"]} />
          <mesh geometry={nodes.iphone_12022_17.geometry} material={materials["blue.017"]} />
          <mesh geometry={nodes.iphone_12022_18.geometry} material={materials["lidar.017"]} />
          <mesh geometry={nodes.iphone_12022_19.geometry} material={materials["lidar.016"]} />
          <mesh geometry={nodes.iphone_12022_20.geometry} material={materials["metal.013"]} />
          <mesh geometry={nodes.iphone_12022_21.geometry} material={materials["screen.023"]} />
          <mesh geometry={nodes.iphone_12022_22.geometry} material={materials["?.008"]} />
          <mesh geometry={nodes.iphone_12022_23.geometry} material={materials["lens.008"]} />
          <mesh geometry={nodes.iphone_12022_24.geometry} material={materials["glass.023"]} />
        </group>
        <group position={[35.93, 42.75, 1.95]} rotation={[-0.89, 1.09, 1.05]} scale={[0.67, 0.67, 0.67]}>
          <mesh geometry={nodes.HeadphonesMesh.geometry} material={materials["Black Glossy"]} />
          <mesh geometry={nodes.HeadphonesMesh_1.geometry} material={materials["Black 05"]} />
          <mesh geometry={nodes.HeadphonesMesh_2.geometry} material={materials["Grey Glossy"]} />
          <mesh geometry={nodes.HeadphonesMesh_3.geometry} material={materials["Black plush"]} />
          <mesh geometry={nodes.HeadphonesMesh_4.geometry} material={materials["Material #99"]} />
        </group>
        <group position={[-0.77, -17.15, 26.52]} rotation={[-0.16, 0.05, -0.06]} scale={[1.06, 1.06, 1.06]}>
          <mesh geometry={nodes.HeadsetMesh.geometry} material={materials.Spugna} />
          <mesh geometry={nodes.HeadsetMesh_1.geometry} material={materials.Cinghie} />
          <mesh geometry={nodes.HeadsetMesh_2.geometry} material={materials.Plastica_bianca} />
          <mesh geometry={nodes.HeadsetMesh_3.geometry} material={materials.Plastica_nera} />
          <mesh geometry={nodes.HeadsetMesh_4.geometry} material={materials.Lenti} />
        </group>
        <group position={[-41.1, 76.19, 9.19]} rotation={[-2.72, 0.7, -2.58]} scale={[1.02, 2.72, 2.72]}>
          <mesh geometry={nodes.RocketMesh.geometry} material={materials["Red.004"]} />
          <mesh geometry={nodes.RocketMesh_1.geometry} material={materials["Body.003"]} />
          <mesh geometry={nodes.RocketMesh_2.geometry} material={materials.Glass} />
        </group>
      </group>
      <group name="Camera" position={[-1.78, 2.04, 23.58]} rotation={[1.62, 0.01, 0.11]}>
        <PerspectiveCamera makeDefault far={1000} near={0.1} fov={28} rotation={[-Math.PI / 2, 0, 0]}>
          <directionalLight
            castShadow
            position={[10, 20, 15]}
            shadow-camera-right={8}
            shadow-camera-top={8}
            shadow-camera-left={-8}
            shadow-camera-bottom={-8}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            intensity={2}
            shadow-bias={-0.0001}
          />
        </PerspectiveCamera>
      </group>
    </group>
  )
}

useGLTF.preload("model3.glb")

import React, { forwardRef } from "react"

const Overlay = forwardRef(({ caption, scroll }, ref) => (
  <div
    ref={ref}
    onScroll={(e) => {
      scroll.current = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)
      scroll.current /= 0.9
      if (scroll.current > 1) scroll.current = 1.0
      caption.current.innerText = `${Math.round(scroll.current.toFixed(2) * 100)}%`
    }}
    class="scroll">
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>my other headset...</h1>
        <br />
        ..is a Quest 2. Also, it's a Quest 1 and there's HTC Vive. Developer Kits DK1 and DK2, and a few handsets, cardboard.. a ViewMaster.. You get the picture.
        <br />
        <br />
        Hello, my name is Matt and I've nurtured a love of 3D graphics and computer programming since I was about eight years of age.
        <br />
        <br />
        This is a project I started on August 27 2021, undergoing 3D model updates during today 2021-09-10. Scroll to find out more.
        <br />
        <br />
        <small style={{ fontSize: "11px", color: "#ffb74d", marginTop: 0 }}>[v0.1a] 2021-09-10 0531 GMT</small>
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>creative technology</h1>
        Prior to the current wave of VR popularity and the cloud revolution, I worked in the <a href="http://www.future.com.au/">post-production industry</a>, with film, tv and
        effects companies around the globe.
        <br />
        <br />
        The tech itself was massive: cabinets of processors, fans and disk arrays but <strong>best of all:</strong> visual effects software that won Academy Awards. Companies like
        Alias|Wavefront and Discreet Logic built authoring software as beautiful as the effects they produce.
        <br />
        <br />
        Before the advent of 3D in the browser, I would enjoy hacking with digital audio software and creating visualisation plugins for music players.
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>performance computing</h1>
        My first commercial VR assignment was in a government contract, as the maintenance manager of{" "}
        <a href="https://www.smh.com.au/technology/virtual-reality-training-for-nsw-rail-staff-20020903-gdflmz.html">two SGI RealityCenters</a>. The installation was worth over
        twenty million dollars with the key purpose of certification for public transport safety and driver training.
        <br />
        <br />
        I'd seen some Big Iron in the digital film houses, but this was different, on par with large defence simulations.
        <br />
        <br />
        Though as it turns out, the <strong>VR revolution</strong> was about to converge with the personal computer. Low-cost, high-performance audiovisual began to appear,
        rivalling the status quo.
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>content creation</h1>
        After three years of non-programming roles in cutting-edge technology companies, I'd resolved to return to application development with added perspective. Graphics
        capability had grown and now for the network.
        <br />
        <br />
        In the years that followed I was commended for my efforts in helping to <strong>create and distribute</strong> a range of interactive educational media content (2D, 3D,
        streaming audiovisual) for a busy <a href="https://www.futureschool.com/">production studio</a>.
        <br />
        <br />
        Then later, I led development in a <a href="https://lovattspuzzles.com/online-puzzles-competitions/">game studio</a>: small in number but high in MAU and advertising
        revenue. Some of the game and puzzle titles we published achieved top App Store rankings.
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>target acquired</h1>
        During 2013, a good friend came to me asking for help on an assignment in their Fine Arts degree, using the <strong>Vuforia Image Recognition SDK</strong>. I'd already done
        some iOS programming and happily obliged.
        <br />
        <br />
        That in itself propelled me into becoming a founding member of a <a href="https://auggd.com"> startup company</a> recently acquired by the Glimpse Group (NYSE:VRAR).
        <br />
        <br />
        In my time there, we built a SaaS platform for deploying white-labelled, mobile AR apps + content for a client base with more than 100 commerical apps.
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>here and now..</h1>
        Again recently I've gravitated toward roles in education and learning: training others in person, or in virtual / augmented reality, or with a world-class learning
        management system.
        <br />
        <br />
        Most recently I've successfully executed on contracts with UK and Australian companies{" "}
        <a href="https://mnemoscene.io" target="_blank">
          [1]
        </a>{" "}
        <a href="https://jimple.it" target="_blank">
          [2]
        </a>
        &nbsp;
        <a href="https://expressionlogic.com" target="_blank">
          [3]
        </a>{" "}
        on mobile AR apps{" "}
        <a href="https://apps.apple.com/gb/app/brixton81/id1559497439?ign-mpt=uo%3D2" target="_blank">
          [4]
        </a>{" "}
        <a href="https://play.google.com/store/apps/details?id=org.independentfilmtrust.brixton81&hl=en_AU&gl=US" target="_blank">
          [5]
        </a>{" "}
        <a href="https://mnemoscene.io" target="_blank">
          [6]
        </a>{" "}
        and web development with vector graphics{" "}
        <a href="jimple_ui.png" target="_blank">
          [7]
        </a>{" "}
        <a href="jimple.png" target="_blank">
          [8]
        </a>
        .
        <br />
        <br />
        <small>
          <ul style={{ margin: 0, padding: 0 }}>
            [1] Feb 2021: Mnemoscene
            <br />
            [2] Jun 2021: Jimple Editor (VetCheck Technologies)
            <br />
            [3] Jul 2021: Expression Logic
            <br />
            [4] Feb 2021: Brixton 81 AR app (iOS)
            <br />
            [5] Feb 2021: Brixton 81 AR app (Android)
            <br />
            [6] Mar 2021: Moriko AR app (iOS/Android)
            <br />
            [7] Aug 2021: Jimple UI, example of AI Draw feature
            <br />
            [8] Aug 2021: Jimple artboards by Luke Feldman
          </ul>
        </small>
        <br />
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1></h1>
        Watch this space as over the coming weeks it transforms into a rich, multimodal experience. Everything here will change.
        <br />
        <br />
        The plan for September:
        <ul>
          <li>replace 3D scene</li>
          <li>visual refinements</li>
          <li>add WebXR interactivity</li>
          <li>enhance UX of navigation</li>
          <li>Progressive Web App compatiblity</li>
          <li>links to games, experiments, other portfolio items</li>
        </ul>
        <br />
        <br />I hope you enjoyed finding out more. Thanks for reading!
      </div>
    </div>
    <span class="caption" ref={caption}></span>
  </div>
))

export default Overlay

import React, { Suspense, useRef } from "react"
import { Canvas } from "@react-three/fiber"
import { Environment, Stars } from "@react-three/drei"
import Model from "./Model"
import Overlay from "./Overlay"
import Effects from "./components/Effects"
import Fireflies from "./components/Fireflies"

export default function App() {
  const overlay = useRef()
  const caption = useRef()
  const scroll = useRef(0)
  return (
    <>
      <Canvas onCreated={(state) => state.events.connect(overlay.current)} raycaster={{ computeOffsets: ({ clientX, clientY }) => ({ offsetX: clientX, offsetY: clientY }) }}>
        <fog attach="fog" args={["indigo", 50, 100]} />
        <Stars radius={400} depth={50} count={1000} factor={15} />
        <ambientLight intensity={0.1} position={[100, 100, 100]} />
        <Suspense fallback={null}>
          <Fireflies count={1500} />
          <Model scroll={scroll} />
          <Environment preset="city" />
        </Suspense>
        {/* <Effects /> */}
      </Canvas>
      <Overlay ref={overlay} caption={caption} scroll={scroll} />
    </>
  )
}
